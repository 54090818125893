import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>RINA</title>
      <div>
        <h1 style={headingStyles}>For you.</h1>
        <div>
          <iframe
            title="spotify"
            src="https://open.spotify.com/embed/playlist/3DSRp72LAjwimI9JUKayGA?utm_source=generator"
            width="320px"
            height="600px"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
